.NotFound-container {
    width: 85%;
    max-width: 480px;
    margin: 3em auto 0;

    .NotFound-main {
        .NotFound-headers {
            h1 {
                font-size: 4em;
                margin: 0 0 .2em;
                font-family: 'Poppins', sans-serif;
            }

            h3 {
                letter-spacing: 1px;
                font-size: 1.3em;
                font-weight: 900;
                margin: 0;
            }
        }
        .NotFound-guidance {
            max-width: 400px;
            margin-top: 4em;
            display: flex;
            flex-direction: column;
            p {
                font-size: 1em;
                margin: 0;
            }
    
            img {
                width: 20px;
                align-self: flex-end;
                margin: 10px 40px 0 0;
            }
        }
    }

    .NotFound-links {
        display: flex;
        flex-direction: column;
        margin-top: 1em;;

        .link-to {
            background: linear-gradient(to right, #28a2a3 0%, #FF32A5 40%, #FF32A5 60%, #FFAE2D 100%);
            color: white;
            text-align: center;
            line-height: 40px;
            text-decoration: none;
            font-family: 'Cubano', sans-serif;
            font-size: 1.2em;
            letter-spacing: 1px;
            font-weight: bold;
            margin-bottom: 30px;
            height: 40px;
            width: 100%;
        }
    }
}
