.location-panel {
  margin: 10px 0;
  display: flex;
  border: #cecece solid 2px;
  padding: 4px;

  .location-panel--info {
    flex-grow: 12;

    .location-panel--title {
      font-size: 20px;
    }

    .location-panel--address {
      font-size: 13px;
      font-style: italic;
    }
  }


  .location-panel--favourite {
    flex-grow: 2;

    .fav {
      color: red;
    }

    .add-favourite {
      float: right;
      background: none;

      i {
        font-size: 28px;
      }
    }
  }
}