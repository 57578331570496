div.notice {
  padding: 10px;
  border: #cecece solid 1px;
  background: #fff;
  border-radius: 4px;

  &.notice--success {
    border: #99f48f solid 1px;
    text-align: center;
    color: #16041c;
    background: #e3f1db;
    font-style: italic;
    font-size: 14px;
    p {
      margin: 0;
    }
  }

  &.notice--warning, &.notice--error {
    border: #f49e8f solid 1px;
    text-align: center;
    color: #16041c;
    background: #f1dfdb;
    font-style: italic;
    font-size: 14px;
    p {
      margin: 0;
    }
  }

  p {
    margin-bottom: 0;
  }

  &.notice--subtle {
    background: #e8e8e8;
  }
}