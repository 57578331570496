.AmigoStatus-container {
    width: 85%;
    max-width: 480px;
    margin: 0 auto;
    text-align: center;


    .AmigoStatus-header {
        h1 {
            width: 70%;
            margin: 0 auto;
        }

        & p {
            margin-top: 0;
            font-size: 1.1em;
        }
    }

    img {
        width: 90%;
        margin: 1em auto 0;
    }

    .AmigoStatus-leave {
        margin-bottom: 2em;
        font-size: 1em;
    }

    .AmigoStatus-button {
        margin-top: 1em !important;
    }
}

