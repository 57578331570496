.rbg-app {
  .loading-icon {
    text-align: center;
    color: #FFF;

    i {
      font-size: 80px;

      &:before {
        content: "\f013";
      }
    }
  }
}

body {
  .full-page-load {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 2000px;
    background: #FFF;
    z-index: 999;
    opacity: 0.5;
  }

  .loading-icon {
    color: #FFF;
    opacity: 1.0;
    margin-top: 5%;
  }
}

body.revolution {
  .loading-icon {
    text-align: center;

    i {
      &:before {
        content: '';
      }

      display: inline-block;
      width: 50px;
      height: 50px;
      //background: url('/assets/images/rev-logo.svg') no-repeat;
      background-size: 51px 51px;
    }
  }
}