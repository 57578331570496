@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://rbg-bookings.s3-eu-west-1.amazonaws.com/production/decuba/bookings.css);
/* Fonts */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.content-box--join-amigo-register .notice, .content-box--join-amigo-register button {
    margin-bottom: 10px;
}

.content-box--join-amigo .notice {
    margin-bottom: 10px;
}

.field--label {
    font-size: 16px;
    text-indent: 16px;
    color: #cecece;
}
.rbg-app .loading-icon{text-align:center;color:#FFF}.rbg-app .loading-icon i{font-size:80px}.rbg-app .loading-icon i:before{content:"\f013"}body .full-page-load{position:fixed;top:0;left:0;width:100%;min-height:2000px;background:#FFF;z-index:999;opacity:0.5}body .loading-icon{color:#FFF;opacity:1.0;margin-top:5%}body.revolution .loading-icon{text-align:center}body.revolution .loading-icon i{display:inline-block;width:50px;height:50px;background-size:51px 51px}body.revolution .loading-icon i:before{content:''}

body.revolution .rbg-modal {
  font-family: "Poppins", "sans-serif";
}

.ReactModal__Body--open .rbg-modal {
  display: block;
}

.rbg-modal {
  display: none;
}

.rbg-modal h1,
.rbg-modal h2,
.rbg-modal h3 {
  margin: 0;
}

.rbg-modal .modal--overlay {
  position: fixed;
  z-index: 1;
  padding-top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}

.rbg-modal .modal--overlay .modal--modal {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: auto;
  padding: 10px 10px 0;
  border-radius: 20px;
  width: 80%;
  outline: none;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.rbg-modal .modal--overlay .modal--modal .loading-icon {
  margin: 0 auto;
  text-align: center;
}

.rbg-modal .modal--overlay .modal--modal .loading-icon i {
  font-size: 100px;
}

.rbg-modal .modal--overlay .modal--modal .modal__content {
  margin: 10px 0 10px 0;
  max-height: 700px;
}

.rbg-modal .modal--overlay .modal--modal .modal__top-buttons {
  align-self: flex-end;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
}

.rbg-modal .modal--overlay .modal--modal .modal__top-buttons button {
  font-size: 22px;
  background: none;
  position: relative;
}

.rbg-modal .modal--overlay .modal--modal .modal__top-buttons button .button-action {
  border-radius: 4px;
  z-index: 99;
  position: absolute;
  top: 40px;
  background: #000;
  color: #fff;
  left: -30px;
  padding: 5px;
}

.rbg-modal .modal--overlay .modal--modal .modal__top-buttons button .button-action:before {
  content: "";
  position: absolute;
  top: -23px;
  border: solid 15px transparent;
  border-bottom-color: #000;
  left: 30px;
  z-index: 98;
}

.rbg-modal .modal--overlay .modal--modal .modal__top-buttons button .button-action a {
  margin: 5px 0;
  z-index: 100;
  display: block;
  width: 75px;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.rbg-modal .modal--overlay .modal__buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px 0;
  background: #fff;
  left: 0;
  display: flex;
  flex-direction: row;
  border-top: #cecece solid 1px;
}

.rbg-modal .modal--overlay .modal__buttons button {
  flex-grow: 1;
  margin: 0 10px;
}

@media (max-width: 300px) {
  .rbg-modal.rbg-modal--full-page .modal--overlay .modal--modal {
    padding: 10px;
  }
  .rbg-modal.rbg-modal--full-page .modal--overlay .modal--modal.has-buttons .modal__content {
    max-height: 90%;
    margin: 0 0 100px 0;
    padding: 0 10px;
    overflow-y: auto;
  }
}

@media (min-width: 300px) {
  .rbg-modal .modal--overlay .modal--modal .modal__content {
    max-height: 500px;
    margin-bottom: 0;
  }
}

@media (max-width: 300px) {
  .rbg-modal.rbg-modal--full-page .modal--overlay {
    top: 0;
    margin: 0;
    left: 0;
    padding: 0;
  }
  .rbg-modal.rbg-modal--full-page .modal--overlay .modal--modal {
    -webkit-transform: none;
            transform: none;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 40px 0 0 0;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    left: 0;
    top: 0;
    transform: none;
  }
  .rbg-modal.rbg-modal--full-page .modal--overlay .modal--modal .modal__content {
    margin: 30px 0 0 0;
    max-height: 85%;
    padding: 0 20px;
    overflow-y: scroll;
  }
  .rbg-modal.rbg-modal--fix-bottom .modal--overlay .modal--modal {
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
    padding: 30px 0 20px 10px;
  }
}
/*# sourceMappingURL=Modal.css.map */
.content-box--panels{display:flex;flex-direction:column;grid-gap:1em;gap:1em}.content-box--panels .panel{display:flex;align-items:flex-end;padding:.5em;height:300px;background-size:cover;background-repeat:no-repeat;background-position:center;border-radius:10px}.content-box--panels .panel .panel--titles{width:100%}@media (max-width: 300px){.content-box--panels .panel{width:100%;flex-grow:12;margin:10px 0}}@media (min-width: 300px){.content-box--panels .panel{margin:10px 10px 20px;flex-grow:6}}.content-box--panels .panel.panel--qr-code{background-size:contain}.content-box--panels .panel h2,.content-box--panels .panel h3{color:#FFF;text-shadow:0px 3px 4px #000;margin:10px}.content-box--panels .panel h2{text-transform:capitalize;font-family:'cubano', sans-serif;letter-spacing:2px;height:30px}

.my-qr-code {
  text-align: center;
  overflow: hidden;
}

.my-qr-code .qr-code {
  max-width: 90%;
  margin-bottom: 1em;
}

.my-qr-code .ios-wallet a img {
  min-height: 80px;
  max-height: 100px;
  max-width: 100%;
}
/*# sourceMappingURL=MyQrCode.css.map */
div.notice{padding:10px;border:#cecece solid 1px;background:#fff;border-radius:4px}div.notice.notice--success{border:#99f48f solid 1px;text-align:center;color:#16041c;background:#e3f1db;font-style:italic;font-size:14px}div.notice.notice--success p{margin:0}div.notice.notice--warning,div.notice.notice--error{border:#f49e8f solid 1px;text-align:center;color:#16041c;background:#f1dfdb;font-style:italic;font-size:14px}div.notice.notice--warning p,div.notice.notice--error p{margin:0}div.notice p{margin-bottom:0}div.notice.notice--subtle{background:#e8e8e8}

body .main-content{margin:3em 0}body .main-content .content-box{position:relative;margin-bottom:10px;color:#fff}body .main-content .content-box h1{font-family:'Cubano', sans-serif;letter-spacing:1.5px;margin-bottom:1em}body .main-content .content-box .content-box-title{position:absolute;top:0;left:0;height:40px;line-height:40px;padding:0;width:100%;color:#FFF}body .main-content .content-box .content-box-title .back-button{margin:0 10px}body .main-content .content-box .content-box-title .back-button{float:left;border:none;background:none;color:#FFF}body .main-content .content-box.has-title{padding-top:55px !important}body .main-content .content-box .content-box-loading{background:#FFF;position:absolute;top:0;left:0;height:100%;width:100%;opacity:0.5}

.PageLayout-separator{height:100vh;display:flex;flex-direction:column;justify-content:space-between}.PageLayout-separator main{margin:0}

.Registration-main {
  padding: 0 1.2em;
}

.Registration-main .content-box--register-intro {
  margin: 0 1em;
}

.Registration-main .content-box--register form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Registration-main .content-box--register form .field {
  padding: 0 10px;
}

.Registration-main .content-box--register form .field--forename, .Registration-main .content-box--register form .field--surname {
  width: 50%;
}

.Registration-main .content-box--register form .field--email, .Registration-main .content-box--register form .field--amigo_code, .Registration-main .content-box--register form .field--mobile {
  width: 100%;
}

.Registration-main .content-box--register form .btn {
  width: 100%;
}

.Registration-main .content-box--register form .notice--already-logged-in {
  width: 100%;
}

.Registration-main .notice {
  margin: .8em;
}
/*# sourceMappingURL=Register.css.map */
button.btn.btn--circular{border-radius:50%}button.btn.btn--circular span{color:#FFF}button.btn.btn--circular span i{color:#FFF;font-size:14px}button.btn.btn--full-width{width:100%}.inline-buttons .btn{margin-right:10px}body.revolution .rbg-app button.btn.btn--text,body.revolution .rbg-modal button.btn.btn--text,body.decuba .rbg-app button.btn.btn--text,body.decuba .rbg-modal button.btn.btn--text{display:inline;background:none;text-transform:none;min-width:0;width:auto;margin:0;color:#191919;font-style:italic;text-decoration:underline;overflow:hidden;text-overflow:ellipsis;padding:0 2px;min-height:auto}body.revolution .rbg-app button.btn.btn--text:hover,body.revolution .rbg-modal button.btn.btn--text:hover,body.decuba .rbg-app button.btn.btn--text:hover,body.decuba .rbg-modal button.btn.btn--text:hover{text-decoration:none}body.revolution .rbg-app button.btn.btn--text:before,body.revolution .rbg-app button.btn.btn--text:after,body.revolution .rbg-modal button.btn.btn--text:before,body.revolution .rbg-modal button.btn.btn--text:after,body.decuba .rbg-app button.btn.btn--text:before,body.decuba .rbg-app button.btn.btn--text:after,body.decuba .rbg-modal button.btn.btn--text:before,body.decuba .rbg-modal button.btn.btn--text:after{width:0;height:0}body.revolution .rbg-app button.btn.btn--outline:hover span,body.revolution .rbg-modal button.btn.btn--outline:hover span,body.decuba .rbg-app button.btn.btn--outline:hover span,body.decuba .rbg-modal button.btn.btn--outline:hover span{color:#000}

.date-input{display:flex;flex-direction:column;width:100%;margin-bottom:1em}

.location-panel{margin:10px 0;display:flex;border:#cecece solid 2px;padding:4px}.location-panel .location-panel--info{flex-grow:12}.location-panel .location-panel--info .location-panel--title{font-size:20px}.location-panel .location-panel--info .location-panel--address{font-size:13px;font-style:italic}.location-panel .location-panel--favourite{flex-grow:2}.location-panel .location-panel--favourite .fav{color:red}.location-panel .location-panel--favourite .add-favourite{float:right;background:none}.location-panel .location-panel--favourite .add-favourite i{font-size:28px}

.AmigoStatus-container{width:85%;max-width:480px;margin:0 auto;text-align:center}.AmigoStatus-container .AmigoStatus-header h1{width:70%;margin:0 auto}.AmigoStatus-container .AmigoStatus-header p{margin-top:0;font-size:1.1em}.AmigoStatus-container img{width:90%;margin:1em auto 0}.AmigoStatus-container .AmigoStatus-leave{margin-bottom:2em;font-size:1em}.AmigoStatus-container .AmigoStatus-button{margin-top:1em !important}

.NotFound-container{width:85%;max-width:480px;margin:3em auto 0}.NotFound-container .NotFound-main .NotFound-headers h1{font-size:4em;margin:0 0 .2em;font-family:'Poppins', sans-serif}.NotFound-container .NotFound-main .NotFound-headers h3{letter-spacing:1px;font-size:1.3em;font-weight:900;margin:0}.NotFound-container .NotFound-main .NotFound-guidance{max-width:400px;margin-top:4em;display:flex;flex-direction:column}.NotFound-container .NotFound-main .NotFound-guidance p{font-size:1em;margin:0}.NotFound-container .NotFound-main .NotFound-guidance img{width:20px;align-self:flex-end;margin:10px 40px 0 0}.NotFound-container .NotFound-links{display:flex;flex-direction:column;margin-top:1em}.NotFound-container .NotFound-links .link-to{background:linear-gradient(to right, #28a2a3 0%, #FF32A5 40%, #FF32A5 60%, #FFAE2D 100%);color:white;text-align:center;line-height:40px;text-decoration:none;font-family:'Cubano', sans-serif;font-size:1.2em;letter-spacing:1px;font-weight:bold;margin-bottom:30px;height:40px;width:100%}

