button.btn {
  &.btn--circular {
    border-radius: 50%;

    span {
      color: #FFF;

      i {
        color: #FFF;
        font-size: 14px;
      }
    }
  }

  &.btn--full-width {
    width: 100%;
  }
}

.inline-buttons {
  .btn {
    margin-right: 10px;
  }
}

body.revolution, body.decuba {
  .rbg-app, .rbg-modal {
    button.btn {
      &.btn--text {
        display: inline;
        background: none;
        text-transform: none;
        min-width: 0;
        width: auto;
        margin: 0;
        color: #191919;
        font-style: italic;
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 2px;
        min-height: auto;

        &:hover {
          text-decoration: none;
        }

        &:before, &:after {
          width: 0;
          height: 0;
        }
      }

      &.btn--outline {
        &:hover {
          span {
            color: #000;
          }
        }
      }
    }
  }
}