body {
  .main-content {
    margin: 3em 0;
    
    .content-box {
      position: relative;
      margin-bottom: 10px;
      color: #fff;

      h1 {
        font-family: 'Cubano', sans-serif;
        letter-spacing: 1.5px;
        margin-bottom: 1em;
      }
  
      .content-box-title {
        .back-button {
          margin: 0 10px;
        }
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        line-height: 40px;
        padding: 0;
        width: 100%;
        color: #FFF;
        .back-button {
          float:left;
          border: none;
          background: none;
          color: #FFF;
        }
      }
  
      &.has-title {
        padding-top: 55px !important;
      }
  
      .content-box-loading {
        background: #FFF;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.5;
      }
    }
  }
  
}