@import '../../Variables';

.content-box--panels {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .panel {
    display: flex;
    align-items: flex-end;
    padding: .5em;

    .panel--titles {
      width: 100%;
    }
    @include phone() {
      width: 100%;
      flex-grow: 12;
      margin: 10px 0;
    }
    @include tablet() {
      margin: 10px 10px 20px;
      flex-grow: 6;
    }
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;

    &.panel--qr-code {
      background-size: contain;
    }

    h2, h3 {
      color: #FFF;
      text-shadow: 0px 3px 4px rgb(0, 0, 0);
      margin: 10px;
    }

    h2 {
      text-transform: capitalize;
      font-family: 'cubano', sans-serif;
      letter-spacing: 2px;
      height: 30px;
    }
  }
}